<template>
  <div class="container-fluid mt-3 profile-wrapper">
    <Form @submit="handleUpdate" v-slot="{isSubmitting, errors }">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Breadcrumb current_page="تعديل الملف الشخصي"/>
        <div class="controls-btns d-flex gap-2 mb-2">
          <button class="btn btn-publish" type="submit" @click="handleUpdate" :disabled="isSubmitting">تعديل</button>
        </div>
      </div>

      <div class="row my-3" v-if="!loading">
        <div class="col-lg-3">
          <el-card class="h-100">
            <div class="form-group">
              <label class="el-tabs__item is-active">صورة الملف الشخصي</label>
              <label class="placeholder-image mt-0" @click="showUploadCenter=true;isSingleUpload=true">
                <img class="w-100" height="220" v-if="user?.image" :src="APP_URL + user?.image" alt="">
                <!--          <img src="/media/cloud-img.png" v-else class="img-float" alt="" />-->
              </label>
            </div>

            <el-dialog :destroy-on-close=true
                       title="مركز الرفع"
                       v-model="showUploadCenter"
            >
              <UploadCenter :is-single-upload="isSingleUpload" @save="handleAddPhoto"/>
            </el-dialog>


            <div class="form-group mt-3">
              <label for="description">وصف المستخدم</label>
              <textarea name="description" id="description" rows="4" class="form-control"
                        v-model="user.description"></textarea>

            </div>
          </el-card>
        </div>
        <div class="col-lg-9 mt-lg-0 mt-3">
          <el-card class="custom-card pb-4">
            <el-tabs v-model="activeTab">
              <el-tab-pane label="بيانات المستخدم" name="userDetails">
                <div class="d-flex flex-wrap gap-3">


                  <reusable-field inputKey="first_name" label-text="الاسم الأول" @update="handleOnChange"
                                  :prev_val="user.first_name"
                                  :errors="errors" error-message="الرجاء إدخال الاسم الأول"
                  />

                  <reusable-field inputKey="last_name" label-text="الاسم الأخير" @update="handleOnChange"
                                  :prev_val="user.last_name"
                                  :errors="errors" error-message="الرجاء إدخال الاسم الأخير"
                  />

                  <reusable-field inputKey="username" label-text="اسم المستخدم" @update="handleOnChange"
                                  :prev_val="user.username"
                                  :errors="errors" error-message="الرجاء إدخال اسم المستخدم"
                  />

                  <reusable-field inputKey="display_name" label-text="الاسم المعروض" @update="handleOnChange"
                                  :prev_val="user.display_name"
                                  :errors="errors" error-message="الرجاء إدخال الاسم المعروض"
                  />


                  <reusable-field inputKey="mobile" label-text="رقم المحمول" @update="handleOnChange"
                                  :prev_val="user.mobile" :notRequired="true"
                  />


                  <reusable-field inputKey="email" label-text="البريد الإلكتروني" @update="handleOnChange"
                                  :prev_val="user.email" :notRequired="true"
                                  :errors="errors" error-message="الرجاء إدخال البريد الإلكتروني"
                  />


                  <reusable-field inputKey="address" label-text="العنوان" @update="handleOnChange"
                                  :prev_val="user.address" :notRequired="true"
                  />

                  <!--                  <div class="form-group">-->
                  <!--                    <label for="password">كلمة المرور</label>-->
                  <!--                    <div class="position-relative password-wrapper">-->
                  <!--                      <Field-->
                  <!--                          :type="isTextShow ? 'text' : 'password'"-->
                  <!--                          name="password"-->
                  <!--                          id="password"-->
                  <!--                          v-model="user.password"-->
                  <!--                          placeholder="كلمة المرور"-->
                  <!--                          class="form-control form-control-solid"-->
                  <!--                          :rules="isRequired"-->
                  <!--                          :class="{'border-red' : errors.password}"-->
                  <!--                      />-->
                  <!--                      <button class="btn top-0 left-0 position-absolute btn-toggle" type="button"-->
                  <!--                              @click="isTextShow = !isTextShow">-->
                  <!--                        <i class="far" :class="!isTextShow ? 'fa-eye' : 'fa-eye-slash'"></i>-->
                  <!--                      </button>-->
                  <!--                    </div>-->
                  <!--                    <span class="text-error">{{ errors.password ? 'الرجاء ادخال كلمة المرور' : '' }}</span>-->
                  <!--                  </div>-->

                  <reusable-field inputKey="facebook" label-text="فيسبوك" @update="handleOnChange"
                                  :prev_val="user.facebook"
                  />


                  <reusable-field inputKey="twitter" label-text="تويتر" @update="handleOnChange"
                                  :prev_val="user.twitter"
                  />


                </div>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </div>


      </div>
      <Loader v-else/>

    </Form>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {Field, Form} from "vee-validate";
import UploadCenter from "@/components/uploadCenter";
import ReusableField from "@/components/formControl/reusableField";
import {getProfileData, updateProfile} from "@/services/auth";
import Loader from "@/components/Loader";
import {useToast} from "vue-toastification";
import {mapGetters} from "vuex";
export default {
  name      : "Profile",
  components: {
    Loader,
    Breadcrumb,
    Form,
    Field,
    UploadCenter,
    ReusableField
  },
  data()
  {
    return {
      toast           : useToast(),
      loading         : false,
      isTextShow      : false,
      activeTab       : "userDetails",
      user            : {},
      sendRequest     : false,
      showUploadCenter: false,
      isSingleUpload  : false,
      avatar          : ''
    }
  },
  mounted()
  {
    this.getUser();
  },
  computed: {
    APP_URL: function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL;
    },
    ...mapGetters(['getCurrentUser'])
  },
  methods : {
    handleOnChange(key, val)
    {
      this.user = {
        ...this.user,
        [key]: val
      }
    },
    isRequired(value)
    {
      return value ? true : 'This field is required';
    },
    getUser()
    {
      this.loading    = true
      const {user_id} = this.$store.getters.getCurrentUser;
      getProfileData(user_id)
          .then(res => {
            this.loading = false
            this.user    = res.data
          })
          .catch(() => this.loading = false)
    },
    handleChangeImage(e)
    {
      let selectedFile = e.target.files;
      if (selectedFile.length !== 0)
      {
        const fileReader  = new FileReader();
        fileReader.onload = (e) => {
          this.user.image = fileReader.result;
        };
        fileReader.readAsDataURL(selectedFile[0]);
      }
    },
    handleUpdate()
    {
      this.sendRequest = true
      const {user_id}  = this.$store.getters.getCurrentUser;
      updateProfile(user_id, this.user)
          .then(res => {
            this.sendRequest = false
            this.toast.success('تم التعديل بنجاح')
          this.$store.dispatch('fetchUser', {...this.getCurrentUser,name:this.user.display_name,image:this.user.image})
          })
          .catch((e) => {
            const errorsList = [...Object.values(e.response.data.errors)]
            this.toast.error(errorsList[0][0])
            this.sendRequest = false
          })
    },
    closeUploadCenter()
    {
      this.showUploadCenter = false;
      this.isSingleUpload   = false;
    },
    handleAddPhoto(photo)
    {
      if (this.showUploadCenter)
      {
        this.user.image =  photo.url;
      }
      this.closeUploadCenter();
    },

  }
}
</script>

<style lang="scss">

.profile-wrapper {
  .form-control {
    width: calc(150px + 17vw);
    max-width: 100%;
  }

  @media (max-width: 568px) {
    .form-control {
      width: 100%;
    }
  }
}


.password-wrapper {
  input {
    padding-inline-end: 2.5rem;
  }

  .btn-toggle {
    box-shadow: none;

    :hover {
      color: #8c0ac4;
    }
  }
}

label.el-tabs__item {
  font-weight: 500;
  position: relative;


  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 90%;
    bottom: 0;
    right: 0;
    background-color: var(--el-color-primary);
  }
}
</style>
